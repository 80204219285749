import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ChangePassword = () => {
  const [email, setEmail] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [expired, setExpired] = useState(false);
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [loginValid, setLoginValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [message, setMessage] = useState('');

  const { slug } = useParams();
  const navigate = useNavigate();

  let api_base = 'https://jcsocialapi-3.onrender.com';

  useEffect(() => {
    const fetchData = async () => {

      console.log('slug', slug)

      const response = await fetch(`${api_base}/api/check_random_reset`, {
        method: 'POST', // Specify the HTTP method
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ slug }), // Pass user_id in the body
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('user', data);

      if (data.expired) {
        setExpired(true);
      } else {
        setEmail(data.email);
      }
      setMounted(true);
    };

    fetchData();
  }, [slug]);

  const updateInput = (value, name) => {
    if (name === 'password') {
      setLoginValid(cpassword && value && value === cpassword);
      setPassword(value);
    } else {
      setLoginValid(password && value && value === password);
      setCPassword(value);
    }
  };

  const sendChange = async () => {
    setLoading(true);

    console.log('password', password)

    const response = await fetch(`${api_base}/api/verify_change_password`, {
      method: 'POST', // Specify the HTTP method
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ slug, password }), // Pass user_id in the body
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if (!data.expired) {
      setFeedbackSent(true);
    } else {
      setMessage("The reset link has expired. Please resend another link and use it within 5 minutes.");
    }

    setLoading(false);
  };

  if (!mounted) return null;

  let style = {};

  console.log('expired', expired)

  if (expired) {
    return (
      <div style={{backgroundColor: '#f7f8fc', maxWidth: 500, margin: 'auto', padding: 50, textAlign: 'center'}}>
      <h1 style={{fontSize: 30}}>This Link Has Expired</h1>
      <p style={{fontSize: 20}}>Please request a new reset link on the Bunch app.</p>

    </div>
    );
  }

  return (
    <div style={{backgroundColor: '#f7f8fc', maxWidth: 500, margin: 'auto', padding: 50, textAlign: 'center'}}>
      <div style={{fontSize: 20}}>Update your password for email</div>
      <div style={{fontSize: 18, fontWeight: 'bold'}}>{email}</div>
      <div>
        {!feedbackSent ? (
            <div style={{marginTop: 20}}>
              <input
                  style={{padding: 10, borderWidth: 1, borderRadius: 3}}
                  type="password"
                  value={password}
                  placeholder="New Password"
                  onChange={(e) => updateInput(e.target.value, 'password')}
                />
              <div style={{marginTop: 10}}>

                <input
                  style={{padding: 10, borderWidth: 1, borderRadius: 3}}
                  type="password"
                  value={cpassword}
                  placeholder="Confirm Password"
                  onChange={(e) => updateInput(e.target.value, 'cpassword')}
                />

                <div style={{marginTop: 10}}></div>

                <button
                  loading={loading}
                  style={{ backgroundColor: loginValid ? '#fdcd37' : '#ded6bc', padding: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 5, width: 200}}
                  disabled={!loginValid}
                  onClick={sendChange}
                >Reset Password</button>

                <div style={{marginTop: 0}}>{message}</div>
              </div>
            </div>
          ) : (
            <div>
              <div style={{marginTop: 20, fontSize: 30, color: 'green', textShadow: '1px 1px 1px #17c517'}}>Your password has been successfully changed.</div>
            </div>
          )}
      </div>
    </div>
  );
};

export default ChangePassword;
