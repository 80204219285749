import React, { useState, useEffect } from "react";

import config from '../config/index.json';
import LazyShow from '../components/LazyShow';
import Divider from '../components/Divider';
import Navbar from "../components/Navbar";
import colors from './colors';

const About = () => {

  const [email, setEmail] = useState(null);
  
  const [info_message, setInfo] = useState('');
  const [success, setSuccess] = useState(false);
  const [selected_index, setSelectedIndex] = useState(0);
  let [tour_started, setTourStarted] = useState(false);

  let { product } = config;
  let { items } = product;

  let firstItem = items[0];
  let secondItem = items[1];

  let api_base = 'https://jcsocialapi-3.onrender.com' // 'http://localhost:3000' 

  let full_page_width = window.innerWidth;
  let full_page_height = window.innerHeight;


  return <div className="whole-wrapper">
    <Navbar />
    <div style={{ flexDirection: 'column', display: 'flex', backgroundColor: 'white'}}>
      <div style={{position: 'absolute', width: full_page_width, height: full_page_height, display: 'flex', flexDirection: 'column'}}>
        <div style={{width: full_page_width,backgroundColor: colors.PRIMARY, height: full_page_height / 2}}></div>
        <div style={{width: full_page_width,backgroundColor: 'white', height: full_page_height / 2 - 100}}></div>
      </div>
      <div style={{zIndex: 1, position: 'relative', flexDirection: 'row', display: 'flex'}}>
        <div className="main-left-side"></div>
        <div className="about main-fold">
          <div className="main-title-about">Our Mission</div>
          
          {/* <div className="main-subtitle">{main_subtitle}</div> */}
          <div className="separator"></div>
          <div className="about below-fold-one-wrapper">
            
            <div className="video-tour-wrapper">
              <iframe width="738" height="415" src="https://www.youtube.com/embed/2qnHhRW9yc0?si=YO5uK3tZ-1fDP4RN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>          
          </div>
          <div className="separator-50"></div>
          <div className="separator-50"></div>
          <div className="main-title-about">Our Team</div>
          
          <div className="separator"></div>
          <div className="separator-50"></div>
            <div className="about-bottom">
              <div className="about-side">
                <div className="about-image">
                  <img width="200px" src="https://diningsocial-assets.s3.amazonaws.com/profile/cc467f19-6954-4151-ba3c-c45c8563511c-1722361301063" />
                </div>
                <div className="about-description">
                  <div className="about-name">Aleksandra Maria Czajka</div>
                  <div className="about-title">Founder, App Developer, Community Leader</div>
                  <div className="about-description-desc">
                  Aleksandra started our meetup group at the end of 2022, bringing people together in larger gatherings. Over time, she realized that smaller groups created better opportunities for real connections—dinners, where people could sit down, share a meal, and have meaningful conversations, felt like the perfect setting. That led to the launch of DiningSocial in June 2023, where she hosted over 100 dinners and brunches, constantly refining the experience based on feedback and conversations with attendees. As a software engineer and community builder, she saw the potential to make these gatherings even more seamless. DiningSocial became the foundation for what would come next—an app designed to help people effortlessly create hangouts, meet in small groups, and connect more easily with locals.
                  </div>
                </div>
              </div>
              <div className="second about-side">
                <div className="about-image">
                  <img width="200px" src="https://diningsocial-assets.s3.amazonaws.com/profile/fdbb6202-4e6b-456b-83c9-af7ae190f11b-1704392157696" />
                </div>
                <div className="about-description">
                  <div className="about-name">Barry</div>
                  <div className="about-title">Community Leader</div>
                  <div style={{paddingBottom: 160}} className="about-description-desc">Barry has been the backbone of our meetup group, tirelessly organizing and hosting our weekly board game events for over a year. Week after week, he has ensured that our community has a welcoming space to come together, play, and bond over friendly competition. His dedication and consistency have kept our group thriving, giving members a reliable place to connect, strategize, and enjoy great games. Without Barry’s efforts, our group would have taken a significant hit—losing not just events but the friendships and camaraderie that have grown from them. His commitment has strengthened our community in ways that go far beyond the game table.</div>
                </div>
              </div>
            </div>
        </div>
        <div className="main-right-side"></div>
      </div>

      

    </div>
    </div>
};

export default About;
