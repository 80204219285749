import React, { useState, useEffect } from "react";

import config from '../config/index.json';
import LazyShow from '../components/LazyShow';
import Divider from '../components/Divider';
import Navbar from "../components/Navbar";
import colors from './colors';

const Home = () => {

  const [email, setEmail] = useState(null);
  
  const [info_message, setInfo] = useState('');
  const [success, setSuccess] = useState(false);
  const [selected_index, setSelectedIndex] = useState(0);
  let [tour_started, setTourStarted] = useState(false);

  let api_base = 'https://jcsocialapi-3.onrender.com' // 'http://localhost:3000' 

  useEffect(() => {
    // Fetch the IP Address
    fetch("https://api64.ipify.org?format=json")
      .then((res) => res.json())
      .then((data) => {
        const userIp = data.ip;
        
        // Send IP to your own API
        return fetch(`${api_base}/api/track`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({data: {  ip: userIp, url: window.location.href  }}),
        });
      })
      .then((res) => res.json())
      .then((response) => console.log("IP sent successfully:", response))
      .catch((err) => console.error("Error:", err));
  }, []);



  const inviteEmail = async () => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(String(email).toLowerCase())) {
      try {
        const response = await fetch(`${api_base}/api/track`, {
          method: 'POST', // Specify the HTTP method
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({data: { email, type: 'android waitlist' }}), // Pass user_id in the body
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setInfo("We'll notify you when Android is out!");
        setSuccess(true)
      } catch (error) {
        console.error('Failed to fetch user:', error);
      }
    } else {
      setInfo("Email is not valid :-(")
    }
  }

  let full_page_width = window.innerWidth;
  let full_page_height = window.innerHeight;

  let style_full_page_width = { width: full_page_width }
  let style_full_page_height = { height: full_page_height }
  let style_main_fold_background = {  }
  let style_main_fold_main_padding = { padding: 100 }
  let style_main_fold_main_title_font_size = { fontSize: 40 }

  let class_main_fold_title = {...style_main_fold_main_title_font_size};
  let class_whole_wrapper = {...style_full_page_width, ...style_main_fold_background}

  let main_title = "Your social circle just expanded.";
  let main_subtitle = "Socialize with Locals - A small Bunch at a Time - Any Day of The Week";
  let main_description = `Go out to coffee, lunch, dinner or find a buddy to go to the opera or concert. Join or create hangouts to meet up with locals - new or familiar faces - and get togeter a small bunch at a time... all for free.`;
  main_description = `Always have someone to go to coffee, lunch, dinner... concert or opera. Create low-key hangouts to meet locals in small groups of 2-6 people. Invite people you know or members based on age, gender and interests. All for FREE.`;

  let comment_bubbles = [
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/bunch_app_image.png",
      text: "As soon as you set up your profile, you’ll be able to see Hangouts you’ve already been invited to, tailored to your profile details.",
      style: { top: -50, left: 112 }
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/bunch_app_eleven.jpeg",
      text: "Any hangouts you see in your account, you've been invited to. RSVP or mark them as 'maybe' to follow for any updates.",
      style: { top: -50, right: 112 }
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/bunch_app_image_three.png",
      text: "Easily create a hangout by specifying what you want to do, where, and whom you'd like to meet.",
      style: { top: -50, left: 100 }
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/bunch_app_image_four.png",
      text: "Invite members you've met or ones you haven't met based on age, gender or interests.",
      style: { top: -50, right: 100 }
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/bunch_app_image_four.png",
      text: "We have more to share below!",
      style: { top: -50, right: 100 }
    }
  ];

  let panels = [
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/free_tech_five.jpg",
      title: "We Just Launched!",
      text: "It's a Movement! We just launched, but growing fast. Even though we started in the Jersey City area, we're spreading by word of mouth and adding locations on demand. Being completely free, we have competition that charges for attendance and doesn't want us around. Help us spread the word and grow this amazing community. Learn more below.",
      style: { top: 0, left: 112 },
      width: full_page_width / 3,
      image_order: 1,
      content_order: 2,
      main_color: colors.PRIMARY,
      image_height: 400,
      image_width: 600,
      title_color: 'black'
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/people_one.jpg",
      title: "Socialize...",
      text: "... on your own time, at your favorite places. Bunch opens up more of society by connecting you with people who are just as eager to meet you as you are to meet them. It's as simple as going to a bar by yourself — just without the sketchy characters!",
      style: { top: 0, left: 112 },
      width: full_page_width / 3,
      image_order: 1,
      content_order: 2,
      main_color: colors.vibrantTurquoise,
      image_height: 400,
      image_width: 600,
      title_color: 'black'
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/people_two.jpg",
      title: "Make BETTER friends",
      text: "Making friends is a journey — enjoy the ride and don't force it. True friends bring happiness and make life more fun. With Bunch, you'll have access to endless locals, so you'll never feel stuck or out of options when it comes to meeting new people.",
      style: {top: 0, left: 112,},
      width: full_page_width / 3,
      image_order: 2,
      content_order: 1,
      main_color: colors.SECONDARY,
      image_height: 400,
      image_width: 600,
      title_color: 'black'
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/people_three.jpg",
      title: "Dating",
      text: "Now listen up, boys... this isn't a dating app. Dating used to feel easier because our society was more connected. Bunch isn't here to play matchmaker, but it is here to help you expand your social circle. Remember when people used to date friends of friends? Step one to that: make some friends! Let Bunch help you start building those connections.",
      style: {top: 0, left: 112,},
      width: full_page_width / 3,
      image_order: 1,
      content_order: 2,
      main_color: colors.blueViolet,
      image_height: 400,
      image_width: 600,
      title_color: '#ffffff'
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/people_four.jpg",
      title: "Easy Spontaneity",
      text: "Forget endless message threads trying to plan meetups. Create a hangout when you're free and see who's around. You can even invite your existing friends.",
      style: {top: 0, left: 112,},
      width: full_page_width / 3,
      image_order: 2,
      content_order: 1,
      main_color: colors.PRIMARY,
      image_height: 400,
      image_width: 600,
      title_color: 'black'
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/people_five.jpg",
      title: "Check out New Places",
      text: `Ever walk by an amazing brunch spot on a Sunday morning but it's too late to rally anyone? With Bunch, it's easy to explore new places in town without going solo or making a big effort to gather friends. Just find locals who are up for checking it out with you.`,
      style: {top: 0, left: 112,},
      width: full_page_width / 3,
      image_order: 1,
      content_order: 2,
      main_color: colors.vibrantTurquoise,
      image_height: 400,
      image_width: 600,
      title_color: 'black'
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/people_six.jpg",
      title: "Networking events",
      text: `Ever been to a networking event, exchanged a pile of phone numbers, social media handles, and business cards, only to go home and realize you can’t get anyone to grab coffee? Bunch makes networking simpler and more meaningful by focusing on real-life connections. Just scan someone’s QR code to instantly connect, add notes about where you met, and create a Hangout to grab coffee later. No more tossing connections into the social media abyss.`,
      style: {top: 0, left: 112,},
      width: full_page_width / 3,
      image_order: 2,
      content_order: 1,
      main_color: colors.SECONDARY,
      image_height: 400,
      image_width: 600,
      text_font_size: 22,
      text_line_height: 36,
      title_color: '#262121'
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/people_seven.jpg",
      title: "Private Parties",
      text: `You can use Bunch to plan private parties and invite anyone that you've already met. If some of your friends are not on Bunch, it's very easy to add them as well. Your attendees will see your private location, date & time, who's coming and communicate in one spot through the party chat.`,
      style: {top: 0, left: 112,},
      width: full_page_width / 3,
      image_order: 1,
      content_order: 2,
      main_color: colors.blueViolet,
      image_height: 400,
      image_width: 600,
      title_color: '#ffffff'
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/people_eight.jpg",
      title: "Your Safety & Comfort",
      text: `Your safety and comfort is the baseline. That's why we only allow public places for meeting new people. If someone doesn't vibe with you, feel free to block them — this app is built for you. Don't just block them if they're unsavory, block them if you don't want to meet up with them again for any reason. We're here to help you meet great people that add to your life.`,
      style: {top: 0, left: 112,},
      width: full_page_width / 3,
      image_order: 2,
      content_order: 1,
      main_color: colors.vibrantTurquoise,
      image_height: 400,
      image_width: 600,
      title_color: 'black',
      text_font_size: 22,
      text_line_height: 36,
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/mission.jpg",
      title: "It's a Movement",
      text: `We, just as you might be, are tired of all this technology available to us and not making our society better. Bunch aims to lead the momvement to open up more of society to us. This is why we believe in keeping most of our base features free forever. It will not work otherwise. Join and Enjoy our movement today.`,
      style: {top: 0, left: 112,},
      width: full_page_width / 3,
      image_order: 1,
      content_order: 2,
      main_color: colors.PRIMARY,
      image_height: 400,
      image_width: 600,
      title_color: 'black'
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/people_nine.jpg",
      title: "Partners",
      text: `Want to use Bunch with your group? We'd absolutely love that. If you need features other than the ones currently out, let's chat. If it's bringing us more members to enjoy Bunch, we can even build them for free.`,
      style: {top: 0, left: 112,},
      width: full_page_width / 3,
      image_order: 1,
      content_order: 2,
      main_color: '#5c74f2',
      image_height: 400,
      image_width: 600,
      title_color: 'black'
    },
    {
      image: "https://diningsocial-assets.s3.amazonaws.com/people_ten.jpg",
      title: "Investors",
      text: `With investors we can grow faster and bring our mission to a much broader audiance much faster. We stress that the most important thing to us is our mission of a more open society. Yes, we will make money with premium features that people want. But, we full heartedly believe that we can only make Bunch fulfill it's mission if most of the base features are free.`,
      style: {top: 0, left: 112,},
      width: full_page_width / 3,
      image_order: 1,
      content_order: 2,
      main_color: colors.dodgerBlue,
      image_height: 400,
      image_width: 600,
      title_color: 'black'
    },
  ]

  let selected_bubble = comment_bubbles[selected_index];

  const nextBubble = () => {
    if(!tour_started) {
      setTourStarted(true);
    } else {
      setSelectedIndex(selected_index+1)
    }
  }

  const prevBubble = () => {
    setSelectedIndex(selected_index-1)
  }

  let prev_enabled = selected_index !== 0;
  let next_enabled = selected_index !== comment_bubbles.length - 1;

  return <div className="whole-wrapper">
    <Navbar />
    <div style={{ flexDirection: 'column', display: 'flex', backgroundColor: 'white'}}>
      <div style={{position: 'absolute', width: full_page_width, height: full_page_height, display: 'flex', flexDirection: 'column'}}>
        <div style={{width: full_page_width,backgroundColor: colors.PRIMARY, height: full_page_height / 2}}></div>
        <div style={{width: full_page_width,backgroundColor: 'white', height: full_page_height / 2 - 100}}></div>
      </div>
      <div style={{zIndex: 1, position: 'relative', flexDirection: 'row', display: 'flex'}}>
        <div className="main-left-side"></div>
        <div className="main-fold">
          <div className="main-title">{main_title}</div>
          {/* <div className="main-subtitle">{main_subtitle}</div> */}
          <div className="main-description">{main_description}</div>
     
          <div className="main-buttons-wrapper">
            <a target="_blank" href={ "https://apps.apple.com/us/app/bunch-social-app/id6737211624"}
              className="iphone-button" >
              <img src="https://diningsocial-assets.s3.amazonaws.com/Apple_Badge.svg" />
            </a>
            
            <a target="_blank" href={"https://play.google.com/store/apps/details?id=io.social.bunch&pli=1"}
              className="android-button" >
              <img src="https://diningsocial-assets.s3.amazonaws.com/Android_Badge.png" width={220} style={{resizeMode: 'contain'}} />
            </a>

          </div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center', paddingTop: 30}}>
            <span className="follow-text">Follow us on</span><a href="https://www.instagram.com/bunch_social"><img height={50} width={50} src="https://diningsocial-assets.s3.amazonaws.com/instagram_logo.png" /></a>
          </div>
        </div>
        <div className="main-right-side"></div>
      </div>


      <div className="mobile-only">
        <div className="below-fold-one-wrapper-wrapper separator">
          <div className="below-fold-one-left-wrapper" style={{background: `linear-gradient(to bottom, ${colors.PRIMARY}, ${colors.SECONDARY})`}}>
            <div className="below-fold-one-left"></div>
          </div>
          <div className="below-fold-one-wrapper" style={{background: `linear-gradient(to bottom, ${colors.PRIMARY}, ${colors.SECONDARY})`}}>
            
          </div>
          <div className="below-fold-one-right-wrapper" style={{height: 200, background: `linear-gradient(to bottom, ${colors.PRIMARY}, ${colors.SECONDARY})`}}>
            <div className="below-fold-one-right" style={{height: 200}}></div>
          </div>
        </div>
      </div>


      <div className="below-fold-one-wrapper-parent">
      <div className="mobile-only">{tour_started ? <div className="comment-bubble" style={selected_bubble.style}>{selected_bubble.text}</div> : null}</div>

        <div className="below-fold-one-wrapper-wrapper">
          
          <div className="below-fold-one-left-wrapper">
            <div className="below-fold-one-left-fold"></div>
          </div>
          {/* <>
          <div className="desktop-only">{tour_started ? <div className="comment-bubble" style={selected_bubble.style}>{selected_bubble.text}</div> : null}</div>
          {!tour_started ? <div className="comment-bubble start-bubble" onClick={()=>{setTourStarted(true)}}><img style={{width: 100, height: 100}} src={'https://diningsocial-assets.s3.amazonaws.com/icon.png'} /><div className="start-tour-text">Start Tour</div></div> : null}
          {<div className="below-fold-one-wrapper">
            <div onClick={prev_enabled ? prevBubble : null}><i className={`fa-solid fa-caret-left image-arrow-left ${prev_enabled ? '' : 'arrow-disabled'}`}></i></div>
            <div className="comment-one"></div>
            <div className="below-fold-one-img-wrapper">
              <img className="bunch-app-image" src={selected_bubble.image} style={{resizeMode: 'contain'}} />
              {!tour_started ? <div className='tour-not-started-over'></div> : null }
            </div>
            <div onClick={next_enabled ? nextBubble : null}><i className={`fa-solid fa-caret-right image-arrow-right ${next_enabled ? '' : 'arrow-disabled'}`}></i></div>
          </div>
          }</> */}

          <div className="below-fold-one-wrapper">
            <div className="tour-title">Take The Tour</div>
            <div className="video-tour-wrapper">
              <iframe width="738" height="415" src="https://www.youtube.com/embed/2X7mXF-oXXM?si=1Fii2fsr_FxQFsOI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>

          <div className="below-fold-one-right-wrapper">
            <div className="below-fold-one-right-fold"></div>
          </div>
        </div>
      </div>
      <div className="desktop-only">
        <div className="below-fold-one-wrapper-wrapper ">
          <div className="below-fold-one-left-wrapper" style={{height: 200, background: `linear-gradient(to bottom, ${colors.PRIMARY}, ${colors.vibrantTurquoise})`}}>
            <div className="below-fold-one-left-top" style={{height: 200}}></div>
          </div>
          <div className="below-fold-one-wrapper" style={{height: 200, background: `linear-gradient(to bottom, ${colors.PRIMARY}, ${colors.vibrantTurquoise})`}}>
            
          </div>
          
          <div className="below-fold-one-right-wrapper" style={{height: 200, background: `linear-gradient(to bottom, ${colors.PRIMARY}, ${colors.vibrantTurquoise})`}}>
            <div className="below-fold-one-right-top" style={{height: 200}}></div>
          </div>
        </div>
        </div>
          <div className="mobile-only">
            <div className="below-fold-one-wrapper-wrapper separator">
              <div className="below-fold-one-left-wrapper" style={{background: `linear-gradient(to bottom, ${colors.SECONDARY}, ${colors.vibrantTurquoise})`}}>
                <div className="below-fold-one-left"></div>
              </div>
              <div className="below-fold-one-wrapper" style={{background: `linear-gradient(to bottom, ${colors.SECONDARY}, ${colors.vibrantTurquoise})`}}>
                
              </div>
              <div className="below-fold-one-right-wrapper" style={{height: 200, background: `linear-gradient(to bottom, ${colors.SECONDARY}, ${colors.vibrantTurquoise})`}}>
                <div className="below-fold-one-right" style={{height: 200}}></div>
              </div>
            </div>
          </div>
      
      {panels.map((panel, i)=>{
        return <><div className="one-panel " style={{borderColor: panel.main_color, backgroundColor: panel.main_color}}>
         
          <div className={`one-panel-image ${panel.image_order === 2 ? 'right-image-borders' : 'left-image-borders'}`} style={{order: panel.image_order, height: panel.image_height}}>
            <img src={panel.image} height={panel.image_height} />
          </div>
         
          <div className="one-panel-content-wrapper" style={{order: panel.content_order}}>
            <div className="one-panel-title" style={{color: panel.title_color}}>{panel.title}</div>
            <div className="one-panel-description" style={{color: panel.title_color, fontSize: panel.text_font_size ? panel.text_font_size : null, lineHeight: panel.text_line_height ? `${panel.text_line_height}px` : null}}>{panel.text}

              { (i === 10 || i === 11) ? <div className="email-text" key={`a${i}`}><a style={{fontSize: 20, textTransform: 'uppercase', color: 'black', textDecorationLine: 'underline'}} href="mailto:team@letsbunch.com">Email Us</a></div>
              : null}
            </div>
          </div>
          
        </div>
        {i !== 9 && i !== panels.length - 1 ? 
        <div className="below-fold-one-wrapper-wrapper separator">
          <div className="below-fold-one-left-wrapper" style={{background: `linear-gradient(to bottom, ${panel.main_color}, ${panels[i+1].main_color})`}}>
            <div className="below-fold-one-left"></div>
          </div>
          <div className="below-fold-one-wrapper" style={{background: `linear-gradient(to bottom, ${panel.main_color}, ${panels[i+1].main_color})`}}>
            
          </div>
          <div className="below-fold-one-right-wrapper" style={{height: 200, background: `linear-gradient(to bottom, ${panel.main_color}, ${panels[i+1].main_color})`}}>
            <div className="below-fold-one-right" style={{height: 200}}></div>
          </div>
        </div> : null}
        {i === 9 ? <div className="below-fold-one-wrapper-wrapper separator separator-big">
          <div className="below-fold-one-left-wrapper " style={{height: 500, background: `linear-gradient(to bottom, ${panel.main_color}, ${panels[i+1].main_color})`}}>
            <div className="below-fold-one-left" style={{height: 500}}></div>
          </div>
          <div className="below-fold-one-wrapper" style={{height: 500, background: `linear-gradient(to bottom, ${panel.main_color}, ${panels[i+1].main_color})`}}>
            <div onClick={()=>{alert(`You are loved ❤️`)}} style={{cursor: 'pointer', position: 'absolute', left: 'calc(50% - 50px)', top: 'calc(50% - 50px)'}}><img style={{width: 100, height: 100}} src={'https://diningsocial-assets.s3.amazonaws.com/icon.png'} /></div>
          </div>
          <div className="below-fold-one-right-wrapper" style={{height: 500, background: `linear-gradient(to bottom, ${panel.main_color}, ${panels[i+1].main_color})`}}>
            <div className="below-fold-one-right" style={{height: 500}}></div>
          </div>
        </div> : null}
        </>
      })}
    </div>
    </div>
};

export default Home;
