import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import Modal from 'react-modal';

const Place = () => {

  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedHangout, setSelectedHangout] = React.useState(null);

  const [info_message, setInfo] = useState('');
  const [error_message, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [place, setPlace] = useState(false);
  const [hangouts, setHangouts] = useState([]);
  const place_id = searchParams.get("place_id");

  let api_base = 'https://jcsocialapi-3.onrender.com' // 

  //let api_base = 'http://localhost:3000'

  const [ip, setIp] = useState("");

  useEffect(() => {
    // Fetch the IP Address
    fetch("https://api64.ipify.org?format=json")
      .then((res) => res.json())
      .then((data) => {
        const userIp = data.ip;
        setIp(userIp);

        // Send IP to your own API
        return fetch(`${api_base}/api/track`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({data: {  ip: userIp, place_id  }}),
        });
      })
      .then((res) => res.json())
      .then((response) => console.log("IP sent successfully:", response))
      .catch((err) => console.error("Error:", err));
  }, []);

  useEffect(() => {
    // Define an async function inside the useEffect
    const fetchUser = async () => {
      if (!place_id) return; // Avoid making a request if user_id is null or undefined
      try {
        const response = await fetch(`${api_base}/api/place_and_events`, {
          method: 'POST', // Specify the HTTP method
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ place_id }), // Pass user_id in the body
        });

        console.log('place_id', place_id);
        console.log('response', response);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('place', data);

        setPlace(data.place); // Update the user state with the response data
        let hangouts = data.hangouts;
        hangouts.sort((a, b)=>a.event_data.when.date < b.event_data.when.date ? -1 : 1)
        setHangouts(hangouts)
      } catch (error) {
        console.error('Failed to fetch user:', error);
      }
    };

    fetchUser();
  }, [place_id]);

  const updateEmail = (email_text) => {
    
    setEmail(email_text);
  }

  const getDayName = (day) => {
    switch(day) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      default:
        return 'Saturday';
    }
}

const getMonthName = (month) => {
  var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];

  return monthNames[month];
}

const getDateSuffix = (date) => {
  // Extract the last digit of the date
  var lastDigit = date % 10;
  
  // Extract the last two digits of the date
  var lastTwoDigits = date % 100;
  
  // 'th' is used for 11-13 as they are exceptions
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return 'th';
  }
  
  // Otherwise, return suffix based on the last digit
  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

const getTimeFromEuropean = (europeanTime) => {
  if(europeanTime && europeanTime.trim() !== "") {
    // Split the input time string into hours and minutes
    let [hours, minutes] = europeanTime.split(':').map(Number);

    // Determine AM or PM
    const period = hours >= 12 ? 'pm' : 'am';

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12 || 12; // Converts '0' to '12' for midnight, handles '12' for noon

    // Format the time string
    return `${hours}:${minutes.toString().padStart(2, '0')}${period}`;
  } else {
    return "";
  }
}

const getHumanDate = (date, include_year) => {
  try {
    if(date && date.trim() !== "") {
      let tokens = date.split('/');

      let month = parseInt(tokens[0]);
      let day = parseInt(tokens[1]);
      let year = parseInt(tokens[2]);

      return `${getDayName(new Date(year, month-1, day).getDay()).substring(0, 3)}, ${getMonthName(month-1).substring(0, 3)} ${day}${getDateSuffix(day)}${include_year ? ` '` + year.toString().substr(2,2) : '' }`;
    } else {
      return "";
    }
  } catch(err) {
      //UtilitiesController.saveLog('getHumanDate error', date + err.stack);
      return "";
  }
}

const goHangout = (hangout) => {
  setSelectedHangout(hangout);
  setIsOpen(true)
}

const rsvpHangout = async () => {
  console.log('selectedHangout', selectedHangout)
  let hangout_id = selectedHangout.id;
  
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(re.test(String(email).toLowerCase())) {
    try {
      setLoading(true)
      console.log('hangout_id', hangout_id);
      console.log('email', email);

      const response = await fetch(`${api_base}/api/rsvp_new_user`, {
        method: 'POST', // Specify the HTTP method
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, hangout_id, url: `place_id=${place_id}` }), // Pass user_id in the body
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      } 

      const data = await response.json();
      console.log('user', data);
     

      setInfo('Your RSVP is registered. You should receive an email to verify.');
      setSuccess(true)
      setLoading(false)
    } catch (error) {
      console.log('err', error);
      setError(error.message);
    }
  } else {
    setError("Email is not valid :-(")
  }
}

const onClose = () => {
  setInfo(null);
  setError(null);
  setSuccess(false);
  setIsOpen(false)
}

let is_mobile = window.innerWidth < 500;

let width =  is_mobile ? window.innerWidth : 800;

let image_width = window.innerWidth;
let image_height = is_mobile ? 200 : 400;

let fontFamily = '';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)', // gray backdrop
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    maxWidth: '600px',
    width: '90%',
    inset: 'unset', // override default positioning
    padding: '2rem',
    borderRadius: '12px',
    background: '#fff',
    boxShadow: '0 0 20px rgba(0,0,0,0.2)',
  }
};

let fontFamilyStandOut = 'JOSEFIN SANS';
let fontFamilyNormal = 'Helvetica';

let the_style = is_mobile ? {padding: 20} : {width, margin: 'auto' };

let font_1 = is_mobile ? {fontSize: 30, lineHeight: '30px', marginBottom: 20} : {fontSize: 50, lineHeight: '60px', marginBottom: 20, marginTop: 30}
let font_2 = is_mobile ? {fontSize: 30, lineHeight: '30px', marginTop: 20, marginBottom: 12, fontFamily: fontFamilyStandOut} : {fontSize: 30, lineHeight: '20px', marginTop: 20, marginBottom: 12, fontFamily: fontFamilyStandOut};

let style_2 = is_mobile ? {cursor: 'pointer', backgroundColor: 'white', padding: 20, borderRadius: 10, width: width - 40, marginBottom: 10, borderWidth: 2, borderColor: '#ccc', flexDirection: 'column', alignContent: 'center', alignItems: 'center', display: 'flex', justifyContent: 'center', display:'flex', textAlign: 'center'} : {cursor: 'pointer', backgroundColor: 'white', padding: 20, borderRadius: 10, width: width - 40, marginBottom: 10, borderWidth: 2, borderColor: '#ccc', flexDirection: 'row', alignContent: 'center', alignItems: 'center', display: 'flex', height: 100, justifyContent: 'space-between', display:'flex'};

let style_3 = is_mobile ? {display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center'} : {display: 'flex', flexDirection: 'column', alignItems: 'flex-start', alignContent: 'flex-start'};

let style_4 = is_mobile ? {maxWidth: 320, alignContent: 'center', display: 'flex', flexDirection: 'column', marginTop: 20} : {maxWidth: 320, alignContent: 'flex-start', display: 'flex', flexDirection: 'column'};

let style_5 = is_mobile ? {fontSize: 20, fontFamily, fontWeight: 600, textAlign: 'center', lineHeight: "20px", alignContent: 'center'} : {fontSize: 20, fontFamily, fontWeight: 600, textAlign: 'left', lineHeight: "28px", alignContent: 'flex-start'}

let style_6 = is_mobile ? {paddingLeft: 10, paddingRight: 5, width: 100, marginTop: 20} : {paddingLeft: 10, paddingRight: 5, width: 100};
let style_7 = is_mobile ? {fontSize: 18, marginTop: 10} : {fontSize: 18};
let style_8 = is_mobile ? {width: 'unset', padding: '0px 0px', textAlign: 'center'} : {width: 300, textAlign: 'center'};
let style_9 = is_mobile ? {textAlign: 'center', fontSize: 18, marginTop: 20} : {textAlign: 'center', fontSize: 30}

let style_10 = is_mobile ? {fontWeight: 700, fontSize: 18, lineHeight: '20px', padding: '3px 0 20px'} : {fontWeight: 700, fontSize: 18, padding: '3px 0 20px'};
let style_11 = is_mobile ? {fontSize: 18, lineHeight: '18px'} : {fontSize: 18, lineHeight: '18px'};

let logo = place && place.place_data ? (place.place_data.site_logo ? place.place_data.site_logo : place.place_data.logo) : '';

logo = is_mobile && place && place.place_data.site_logo_mobile ? place.place_data.site_logo_mobile : logo;

return place ? <div className="whole-wrapper">
  <Navbar />
  <div style={{backgroundColor: '#f7f8fc', margin: 'auto', fontFamily: fontFamilyNormal}}>
  <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="My Modal"
      style={customStyles}
    >
      <div style={{display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center'}}>
        <div style={{flexDirection: 'row', justifyContent: 'space-between', width: '100%', display: 'flex', alignContent: 'center', alignItems: 'center'}}>
          <div style={{alignSelf: 'flex-start', borderRadius: 100}}><button style={{ borderRadius: 100, width: 30, height: 30, borderWidth: 1}} onClick={onClose}>x</button></div>
        <div style={{fontSize: 20, fontWeight: 'bold'}}>RSVP</div>
        <div style={{width: 30}}></div>
        </div>
        <div style={style_9}>{selectedHangout?.event_data.what}</div>
        <div style={{textAlign: 'center'}}>
          <div style={{fontSize: 20, fontWeight: 600}}>
            {getHumanDate(selectedHangout?.event_data.when.date)}
          </div>
          <div style={{fontSize: 20}}>
            {getTimeFromEuropean(selectedHangout?.event_data.when.time)}
          </div>
        </div>
        <div style={{width: '100%', height: 20}}></div>
        <div style={style_8}>We’ll send you an email with the details. Please be sure to confirm your RSVP by completing your profile on the Bunch app — it’s completely free!</div>
        <div style={{width: '100%', height: 30}}></div>
        
        {info_message ? <div style={{color: success ? 'green' : 'red', marginBottom: 20, textAlign: 'center'}}>{info_message}</div>  : 
        <div style={{width: 200, display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center'}}>
          <div style={{width: '100%'}}>
            <div style={{flexDirection: is_mobile ? 'column' : 'unset', display: is_mobile ? 'flex' : 'unset', alignItems: 'center'}}>
              <input value={email || ''} // Ensures the input is controlled
              onChange={(e) => updateEmail(e.target.value)} type="email" style={{width: 'calc(100% - 20px)', borderWidth: 1, borderRadius: 3, padding: 10}} placeholder={"Your email"} />
              {is_mobile ? <div style={{height: 10}}></div> : null}
            </div>
          </div>
          <div style={{width: '100%', marginTop: 10}}>
            <button onClick={loading ? null : rsvpHangout} style={{cursor: 'pointer', backgroundColor: '#fdcd37', padding: 10, paddingTop: 10, paddingBottom: 10, borderRadius: 3, borderWidth: 0, width: '100%'}}> {loading ? (
                <span className="spinner" />
              ) : (
                "RSVP"
              )}</button>
          </div>
          {error_message ? <div style={{color: success ? 'green' : 'red', marginBottom: 20, textAlign: 'center'}}>{error_message}</div> : null}
        </div>}
        <div style={{width: '100%', height: 50}}></div>
      </div>
    </Modal>
  <div style={{margin: 'auto'}}>
    <img style={{width: image_width, height: image_height, margin: 'auto', objectFit: is_mobile ? 'cover' : 'cover'}}  src={logo} />
  </div>
  <div style={the_style}>
    <h1 style={font_1}>Make Friends & Socialize</h1>
  
  <div style={{  margin: 'auto'}}>
{/*   <p style={font_2}>{place && place.place_data ? place.place_data.name : null}</p>
 */}  <div style={style_11}>Meet other locals in your area over lunch or dinner—make friends, share stories, and just enjoy good company.</div>
  <div style={style_10}>No attendance fees, no pressure—just good vibes!</div>

  <div style={{fontWeight: 700, fontSize: '14px', padding: '0px 0 30px'}}>*All communication will take place through the <a style={{textDecorationLine: 'underline'}} href="https://www.letsbunch.com">Bunch app</a>.You can RSVP here for convenience, but please make sure to download the app before attending. It's the only way we'll share event updates and the exact meeting table for your group.</div>

  {hangouts && hangouts.length ? <div>
    {hangouts.map(hangout=>{

      let address = hangout.event_data.where.address.split(',')[0]

      return <div onClick={()=>{goHangout(hangout)}} style={style_2}>
        <div style={style_3}>
          <div style={{fontSize: 20, fontWeight: 600}}>{getHumanDate(hangout.event_data.when.date)}</div>
          <div><div style={{fontSize: 18}}>{getTimeFromEuropean(hangout.event_data.when.time)}</div></div>
        </div>
        <div style={style_4}>
          <div  style={style_5}> {hangout.event_data.what}
          </div>
          <div style={{maxWidth: 320}}><div style={style_7}>{address}</div></div>
        </div>

        <div style={style_6}>
          <div className="date-wrapper">
            <div style={{padding: 10, borderRadius: 5, backgroundColor: '#fdcd37', fontWeight: 700, width: 100, justifySelf: 'flex-end', textAlign: 'center'}}>RSVP</div>
          </div>
        </div>
      </div>
      })}
  </div> : null}

  {/* {success ? 
    <div style={{marginTop: 40,fontSize: 30, color: 'green', textShadow: '1px 1px 1px #17c517'}}>Invite sent!<br/> <span style={{fontSize: 20}}>See you soon!</span></div>
  :
  <div style={{}}>
    <div style={{marginTop: 20, marginBottom: 20}}>
    <div style={{fontSize: 18}}><b>Option 1:</b></div>
    <div style={{height: 5}}></div>
    <div style={{fontSize: 16}}>Keep doing what you're doing! We'll link your email to this RSVP and send you instructions on how to register on the Bunch app.</div>
    <div style={{height: 10}}></div>
    <div style={{flexDirection: is_mobile ? 'column' : 'unset', display: is_mobile ? 'flex' : 'unset'}}>
      <input value={email || ''} // Ensures the input is controlled
        onChange={(e) => updateEmail(e.target.value)} type="email" style={{width: is_mobile ? 'unset' : 200, borderWidth: 1, borderRadius: 3, padding: 10}} placeholder={"Your email"} />
        {is_mobile ? <div style={{height: 10}}></div> : null}
      <button onClick={inviteEmail} style={{marginLeft: is_mobile ? 0 : 10, backgroundColor: '#fdcd37', padding: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 5}}>Send</button>
      </div>
    </div>
    {info_message ? <div style={{color: success ? 'green' : 'red', marginBottom: 20, textAlign: 'left'}}>{info_message}</div> : null}

    <div style={{fontSize: 18}}><b>Option 2:</b></div>
    <div style={{height: 5}}></div>
    <div style={{fontSize: 16, lineHeight: '28px'}}>Download the app now at <a style={{fontSize: 20, color: 'blue', textDecorationLine: 'underline'}} href="https://www.letsbunch.com">letsbunch.com</a> and enter the code <span style={{fontSize: 20, fontWeight: 'bold'}}>{place.place_data.code}</span> at registration.</div>
  </div>} */}
  <div style={{height: 100}}></div>
  <div style={{height: 1, backgroundColor: '#cdc7c7'}}></div>
  <div style={{height: 50}}></div>
  <h1 style={{fontSize: 20}}>About Bunch 🍓</h1>
  <div style={{fontSize: 18, lineHeight: "26px"}}><b>Bunch is a brand-new, FREE app in Jersey City designed to make society feel more open and connected.</b> We believe in fostering a world that feels less lonely—one small gathering at a time.</div>
  <div style={{height: 20}}></div>
  <div style={{fontSize: 18, lineHeight: "26px"}}>With Bunch, you take the lead in creating <b>Bunches</b>—small, 2-6 person Hangouts centered around an activity of your choice at a public place. <b>You also decide who joins!</b> Invite members based on age, gender, and interests, or simply use it with your own circle of friends.</div>
  <div style={{height: 20}}></div>
  <div style={{fontSize: 18, lineHeight: "26px"}}>Use it also when meeting new folks IRL. <b>Swiftly scan your QR code to be popped into each other's connections on Bunch.</b> Because Bunch is ONLY for in-person hangouts, it's easier to reach out later for a coffee follow-up rather than throwing your connections into your social media abyss or exchanging phone numbers.</div>
  <div style={{height: 20}}></div>
  <div style={{fontSize: 18, lineHeight: "26px"}}>Think of Bunch as your local social connector—helping you expand your social circle in a way that feels natural, easy, and fun.</div>
  <div style={{height: 100}}></div>
  </div>
</div>
</div>
</div> : null
};

export default Place;
