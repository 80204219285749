import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const BugReport = () => {
  const [email, setEmail] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [expired, setExpired] = useState(false);
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const [loginValid, setLoginValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [message, setMessage] = useState('');
  const [feedback, setFeedback] = useState('');
  const [contains_email, setContainsEmail] = useState(false);

  let location = useLocation();

  const { slug } = useParams();
  const navigate = useNavigate();

  let api_base = 'https://jcsocialapi-3.onrender.com';

  useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const email_q = queryParams.get("email");
      const feedback_q = queryParams.get("feedback");
    
      if(email_q) {
        setContainsEmail(true);
        setEmail(email_q);
      }

      if(feedback_q) {
        setFeedback(feedback_q)
      }

      if(email_q && feedback_q) {
        setLoginValid(feedback_q.length > 10 && email_q.length > 4)
      }

      setMounted(true);
  }, []);

  const updateInput = (value, name) => {
    setLoginValid(value.length > 10 && email)
    setFeedback(value)
  };

  const updateEmail = (value, name) => {
    setLoginValid(feedback.length > 10 && value.length > 4)
    setEmail(value)
  };

  const sendChange = async () => {
    setLoading(true);

    console.log('password', password)

    const response = await fetch(`${api_base}/api/save_error`, {
      method: 'POST', // Specify the HTTP method
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ error_data : { name: 'bug_report', email, feedback } }), // Pass user_id in the body
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if (!data.expired) {
      setFeedbackSent(true);
    } else {
      setMessage("The reset link has expired. Please resend another link and use it within 5 minutes.");
    }

    setLoading(false);
  };

  if (!mounted) return null;

  let style = {};

  console.log('expired', expired)

  if (expired) {
    return (
      <div style={{backgroundColor: '#f7f8fc', maxWidth: 500, margin: 'auto', padding: 50, textAlign: 'center'}}>
      <h1 style={{fontSize: 30}}>This Link Has Expired</h1>
      <p style={{fontSize: 20}}>Please request a new reset link on the Bunch app.</p>

    </div>
    );
  }

  return (
    <div style={{backgroundColor: '#f7f8fc', maxWidth: 500, height: 400, marginTop: 50, margin: 'auto', padding: 50, textAlign: 'center'}}>
      <div style={{fontSize: 20}}>Submit a bug report 🐛</div>
      {contains_email ? <div style={{fontSize: 18, fontWeight: 'bold'}}>{email}</div> : null}
      <div>
        {!feedbackSent ? (
            <div style={{marginTop: 20, flexDirection: 'column'}}>
              {!contains_email ?  <div style={{marginBottom: 20}}>
                <div style={{marginBottom: 5}}>Your email (so that we can reply)</div>
                <input
                  style={{padding: 10, borderWidth: 1, borderRadius: 3}}
                  type="text"
                  value={email}
                  placeholder=""
                  onChange={(e) => updateEmail(e.target.value)}
                /></div> : null}
              <div></div>

              {!contains_email ?  <div style={{marginBottom: 5}}>
                Your bug report
                </div> : null}
              <textarea
                  style={{padding: 10, borderWidth: 1, borderRadius: 3, minHeight: 200}}
                  type="text"
                  
                  value={feedback}
                  placeholder=""
                  onChange={(e) => updateInput(e.target.value, 'report')}
                />
              <div style={{marginTop: 10}}>

                <button
                  loading={loading}
                  style={{ backgroundColor: loginValid ? '#fdcd37' : '#ded6bc', padding: 20, paddingTop: 10, paddingBottom: 10, borderRadius: 5, width: 200}}
                  disabled={!loginValid}
                  onClick={sendChange}
                >Submit Report</button>

                <div style={{marginTop: 0}}>{message}</div>
              </div>
            </div>
          ) : (
            <div>
              <div style={{marginTop: 20, fontSize: 30, color: 'green', textShadow: '1px 1px 1px #17c517'}}>Thank you. Your report has been submited.</div>
            </div>
          )}
      </div>
    </div>
  );
};

export default BugReport;
